<template>
  <SchoolTeacherLeaveControl v-if="type === 1"></SchoolTeacherLeaveControl>
  <SchoolStaffLeaveControl v-else-if="type === 2"></SchoolStaffLeaveControl>
</template>

<script>
import SchoolTeacherLeaveControl from "@/modules/school/components/management/leave/entitlement/control/SchoolTeacherLeaveControl";
import SchoolStaffLeaveControl from "@/modules/school/components/management/leave/entitlement/control/SchoolStaffLeaveControl";
export default {
  name: "SchoolLeaveTableControl",
  components: { SchoolStaffLeaveControl, SchoolTeacherLeaveControl },
  data() {
    return {
      type: 1,
    };
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },

  methods: {
    checkRoute(name) {
      switch (name) {
        case "schoolLeaveEntitlement":
          this.type = 1;
          break;
        case "staffLeaveEntitlement":
          this.type = 2;
          break;
        default:
          this.type = 1;
          break;
      }
    },
  },

  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
