<template>
  <div>
    <SchoolLeaveTableControl class="mb-5"></SchoolLeaveTableControl>

    <AppPageCard>
      <template #card-title> {{ $t("EDULEAVE.ENTITLEMENT") }} </template>
      <template #card-toolbar>
        <!-- begin: b-nav tabs  -->
        <b-nav pills class="nav-light-primary font-weight-bolder">
          <b-nav-item to="teacher" exact exact-active-class="active">{{
            $t("EDUATTENDANCE.TEACHER.TEACHER")
          }}</b-nav-item>
          <b-nav-item to="staff" exact exact-active-class="active">{{
            $t("EDUATTENDANCE.STAFF.STAFF")
          }}</b-nav-item>
        </b-nav>
        <!-- end: b-nav tabs  -->

        <!-- begin: refresh button  -->
        <b-button
          variant="primary"
          class="btn btn-icon btn-light btn-hover-primary ml-4"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <!-- end: refresh button  -->
      </template>
      <!-- begin: router view  -->
      <div class="mt-5">
        <router-view> </router-view>
      </div>
      <!-- end: router view  -->
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolLeaveTableControl from "@/modules/school/components/management/leave/entitlement/control/SchoolLeaveTableControl";
export default {
  name: "SchoolLeaveEntitlementPage",
  components: { SchoolLeaveTableControl, AppPageCard },
  data() {
    return {
      type: 1,
    };
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },
  methods: {
    refresh() {
      if (this.type === 1) {
        this.$store.dispatch("fetchTeacherList");
      } else if (this.type === 2) {
        this.$store.dispatch("fetchStaffList");
      }
    },
    checkRoute(name) {
      switch (name) {
        case "schoolLeaveEntitlement":
          this.type = 1;
          break;
        case "staffLeaveEntitlement":
          this.type = 2;
          break;
        default:
          this.type = 1;
          break;
      }
    },
  },

  watch: {
    $route(to, from) {
      console.log("hello", to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
